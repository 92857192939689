@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.pad-left {
  padding-left: 3vw;
}

/* On screens smaller than 575 */
@media screen and (max-width: 575px)
{
  .description {
    font-size: 0.8em;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 600;
  }

  .footer {
    background-position-y: -120px;
    background-position-x: -360px;
  }
}

/* mid-size */
@media screen and (min-width: 575px) and (max-width: 1080px )
{
  .description {
    font-size: 2vw;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
  }

    .footer {
    background-position-y: -70px;
    background-position-x: -250px;
  }
}

/* 1080 */
@media screen and (min-width: 1080px)
{
  .description {
    font-size: 1.5em;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
  }

  .footer {
    background-position-y: -30px;
  }
}

@media screen and (min-width: 2500px)
{
  .footer {
    background-position-y: 0px;
  }
}

.subheader-bg {
  position: relative;
  display: inline-block;
  padding: 1em 11em 1em 13em;
  overflow: hidden;
  color: #2e323b;
}

.subheader-bg:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(46, 50, 59,0.12);
  transform-origin: 100% 0;
  transform: skew(-45deg);
  z-index: -1;
}

.subheader-bg:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  background: #fff;
  transform-origin: 100% 0;
  transform: skew(-45deg);
  z-index: 1;
}

.container {
  padding-right: 0 !important;
}

::selection {
  color: teal;
  background: salmon;
}

.contact-row {
  margin-top: 1em;
}

.sub-header {
  font-weight: 400;
  font-size: 2em;
  font-family: 'Dancing Script';
}

.carousel-caption {
  text-shadow: black 2px 2px 16px;
  font-size: 1.5em;
  font-weight: 600;
  /* font-family: 'Dancing Script'; */
  /* font-family: 'Montserrat'; */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

